
.supportLink{
    margin: 12px 0 0 0px;
    font-size: 16px !important;
    display: inline-block;
    text-align: right;
    width: 74%;
}

.supportEmail{
    margin: 12px 0 0 0px;
    color: #92b9e0;
    font-size: 16px !important;
    display: inline-block;
    text-align: right;
    width: 5%;
}

.supportEmail:active, .supportEmail:focus, .supportEmail:visited{
    color: #92b9e0 ;
    text-decoration: none ;
}

@media only screen and (max-width: 680px){

    .supportLink{
        margin: 12px 0 0 0px;
        font-size: 16px !important;
        display: inline-block;
        text-align: right;
        width:69%
    }

    .supportEmail{
        margin: 12px 0 0 0px;
        color: #92b9e0;
        font-size: 16px !important;
        display: inline-block;
        text-align: right;
        width: 5;
    }
    
    .supportEmail:active, .supportEmail:focus, .supportEmail:visited{
        color: #92b9e0 ;
        text-decoration: none ;
    }
}


@media only screen and (max-width: 560px){

    .supportLink{
        margin: 12px 0 0 0px;
        font-size: 12px !important;
        display: inline-block;
        text-align: right;
        width:71%
    }

    .supportEmail{
        margin: 12px 0 0 0px;
        color: #92b9e0;
        font-size: 12px !important;
        display: inline-block;
        text-align: right;
        width: 5;
    }
    
    .supportEmail:active, .supportEmail:focus, .supportEmail:visited{
        color: #92b9e0 ;
        text-decoration: none ;
    }
}