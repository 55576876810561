/* Special Font */

@font-face {
	font-family: helveticaregular;
	src: url(fonts/helvetica_0-webfont.eot);
	src: url(fonts/helvetica_0-webfont.eot?#iefix) format('embedded-opentype'),
		url(fonts/helvetica_0-webfont.woff) format('woff'), url(fonts/helvetica_0-webfont.ttf) format('truetype'),
		url(fonts/helvetica_0-webfont.svg#helveticaregular) format('svg');
	font-weight: 400;
	font-style: normal;
}

.versioninfo {
	text-align: center;
	color: #e6e4e4;
}

/* style copied */

.user_icon {
	background: url(../images/user_icon.png) no-repeat;
	background-size: 100%;
	width: 65px;
	height: 65px;
	background-position: center;
	margin-top: 10px;
	margin-left: 76px;
}

.three_line {
	background: url(../images/more_options.png) no-repeat;
	background-size: 100%;
	width: 35px;
	height: 30px;
	background-position: right;
}

.options_menu {
	float: right;
	margin-right: 40%;
}

.options_menu_trend_page {
	float: right;
	margin-right: 39.5%;
}

.options_menu_waveform_page {
	float: right;
	margin-right: 39.5%;
}

.options_userId {
	text-align: center;
	font-size: 20px;
	font-weight: bolder;
	margin-top: 5px;
}

.options_link {
	color: #92b9e0;
}

.more_option {
	border: solid 2px white;
	margin: -62px -12px;
	background: #12141c;
	position: absolute;
	z-index: 2000;
	width: 250px;
	min-width: 250px;
	min-height: 200px;
	height: auto;
	border-radius: 5%;
	list-style-type: none;
	font-size: 17px;
	padding: 1px 15px 25px 15px;
}

@media (max-width: 730px) {
	.options_menu {
		margin-right: auto;
	}

	.options_menu_trend_page {
		margin-right: auto;
	}

	.options_menu_waveform_page {
		margin-right: auto;
	}

	.more_option {
		margin: -62px -295px;
	}
}

@media (min-width: 731px) and (max-width: 775px) {
	.options_menu_trend_page {
		margin-right: 285px;
	}

	.options_menu_waveform_page {
		margin-right: 285px;
	}
}

@media (max-width: 560px) {
	.more_option {
		margin: -42px -295px;
	}
}

@media (max-width: 480px) {
	.more_option {
		margin: -25px -292px;
	}

	.three_line {
		width: 30px;
		height: 25px;
	}
}

/* Pop Up on login CSS */
.announcement_font {
	font-size: 20px;
}

.announcement_button {
	background-color: #12141c;
	border-radius: 20%;
	padding: 1% 20%;
	text-align: center;
	color: #fff;
	font-size: 20px;
	border: solid 2px #50e3c2;
}

.announcement_box {
	border: solid 2px #50e3c2;
	margin: 0 0 10px 0;
	background: #12141c;
}

/* CSS for Analytics Link on Equipment Portfolio Page */

.analyticsLink_font {
	font-size: 20px;
	color: #50e3c2 !important;
	text-decoration: underline !important;
}

.analyticsLink_font:hover,
.analyticsLink_font:focus {
	color: #50e3c2 !important;
	text-decoration: underline !important;
}

.analyticsLink_button {
	background-color: #12141c;
	border-radius: 20%;
	padding: 1% 20%;
	text-align: center;
	color: #fff;
	font-size: 20px;
	border: solid 2px #50e3c2;
}

.analyticsLink_box {
	border: solid 2px #50e3c2;
	margin: 0 0 10px 0;
	background: #12141c;
}

@media (min-width: 768px) {
	.mobile_size {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.desktop_size {
		display: none !important;
	}

	.analytics_msg {
		font: white;
		font-size: 20px;
	}
}

/* EquipmentKW style css */
.newEqwH1 {
	float: right;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
}

.newEqwH2 {
	float: left;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 1px;
}

.newEqwH4 {
	float: left;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
}

.newEqH3 {
	clear: both;
}

/* End EquipmentKW style css */

/* powerMeterAdminPage start */

.avgHeader1 {
	margin-left: 3px;
}

.avgHeader2 {
	margin-left: 3px;
	margin-top: 1px;
}

/* powerMeterAdminPage end */

/* TopNavigation start */

.hideIconStatus {
	display: none !important;
}

/* TopNavigation end */

/* TrendingPage start */

.subTitleHStyle {
	padding: 5px 0 0 5px;
}

.waveHdRightStyle {
	padding: 0 0 0 0;
}

.dataPointIntervalMinutesStyle {
	display: inherit !important;
}

/* TrendingPage end */

/* WaveForPage start */

.waveHdRightStyleInfo {
	padding: 0 0 0 0 !important;
}

.waveStatus {
	display: inherit !important;
}

.harmonicSpectrum {
	float: right !important;
}

/* WaveFormPage end */

/* end */

.equTriangleIcon {
	background: url(../images/blue_triangle.png) no-repeat;
	background-size: 10%;
	width: 310px;
	height: 35px;
}

.eqLeftMar {
	padding-left: 35px;
	padding-top: 3px;
	font-size: 17px;
}

/* class used for sessions tab customer name data */
.eqRightMar {
	float: right;
	margin-top: -30px;
	font-size: 17px;
	padding-right: 10px;
}

.rightSec {
	float: right;
	margin-top: -40px;
}

.equTriangleDownIcon {
	background: url(../images/blue_triangle_down.png) no-repeat;
	background-size: 10%;
	width: 310px;
	height: 35px;
}

.waveRMP {
	font-size: 10px;
}

.marWvafeFormFieldVoltageAndCurr {
	margin-right: 450px;
}

.dateInHeader {
	font-size: 16px;
	margin-top: 3px;
}

.dis {
	display: inline-block;
}

.waveformstatusfont {
	font-size: 20px;
}

.divposition {
	margin-top: -24px;
	text-align: center;
}

.eqType {
	margin-bottom: 10px;
	font-size: 18px;
}

.eqTypesplmar {
	width: min-content;
	margin: auto;
}

.right {
	float: right;
	margin-top: -47px;
}

.nodenamefont {
	margin-top: 6px;
	font-size: 9px !important;
}

.serialnofont1 {
	font-size: 16px;
}

.locationNamefont1 {
	font-size: 20px;
}

.nodenamefontspl {
	width: 58px;
	overflow-wrap: break-word;
}

.mainPowerReading {
	width: 180px !important;
	/* margin-left: -32px !important; */
	margin-top: 5px !important;
}

.plotSNTitle {
	font-size: 15px !important;
	padding-top: 5px !important;
}

.paddingZero {
	padding: 0px 0px !important;
}

.chartContainerVoltage {
	height: 400px;
}

.chartContainerMain {
	display: block;
	border: solid 2px #4eac97;
	margin: 20px 10px 20px 10px;
	padding: 2px 2px 2px 2px;
	background: #12141c;
}

.chartMargin {
	margin: 5px 5px 5px 5px;
}

.chartHeader {
	text-align: center;
	font-size: 20px;
	height: 30px;
}

.chartContainer {
	height: 290px;
}

.splBdRadius {
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
}

.loginPage .wrapperH {
	max-width: 720px;
	margin: 0 auto;
	padding: 0 5px;
}

.reportPage .wrapperH {
	max-width: 720px;
	min-width: 248px;
	margin: 0 auto;
	padding: 0 5px;
}

.wrapperHW {
	max-width: 96%;
	min-width: 248px;
	margin: 0 auto;
	padding: 0 5px;
}

.specialWrapper {
	width: 100%;
	margin: 0 auto;
}

.loginPage .loginH {
	text-align: center;
}

.loginPage .logoBigH a {
	width: 300px;
	height: 100px;
	display: inline-block;
	margin: 14px 0 32px;
	background: url(../images/volta_insite_logo_2024.png) no-repeat;
}

.labelH {
	font-size: 20px;
	color: #fff;
	display: block;
	margin: 0 0 6px 7px;
}

.subLabels {
	font-size: 16px;
	color: #fff;
	display: block;
	margin: 2px 0 0 3px;
}

.forgotLink {
	color: #92b9e0;
	margin: 12px 0 0 7px;
	font-size: 16px;
	display: inline-block;
}

.forgotLink:hover {
	color: #92b9e0;
	text-decoration: underline;
}

.addedelpsis {
	text-overflow: ellipsis;
	overflow: hidden;
}

.continueBtnH {
	text-align: center;
}

.recoverAlert {
	margin: 100px 0 12px;
	text-align: center;
}

.mailNote {
	font-size: 16px;
	display: block;
	width: 70%;
	padding: 0 0 0 6px;
}

.preloaderSize {
	fill: #e3e3e3 !important;
	height: 100px;
	width: 100px;
}

.reportPage .loginH {
	text-align: left;
}

.reportPage .logosmallH {
	float: left;
}

.reportPage .dataH,
.reportPage .timeH {
	float: right;
	margin: 15px 3px 0 0;
}

.reportPage .dataH,
.reportPage .timeH {
	font-size: 18px;
}

.reportPage .logosmallH a {
	width: 300px;
	height: 100px;
	display: inline-block;
	margin-bottom: 20px;
	background: url(../images/volta_insite_logo_2024.png) no-repeat;
}

.bgOrange {
	background: #f5a523 !important;
}

.blueBoxH {
	border: solid 2px #4990e2;
	margin: 0 0 10px 0;
	background: #12141c;
}

.titleH {
	font-size: 22px;
	padding: 6px;
	border-bottom: solid 2px #4990e2;
	margin: 0 0 4px 0;
}

.nodeStatusFS {
	font-size: 18px;
}

.performH {
	font-size: 22px;
}

.seperateLine {
	display: inline-block;
	width: 100%;
}

.equListH {
	padding: 0 5px 0 20px;
}

.waforFormDropdown {
	margin-bottom: -10px;
}

.waveformSelectContent {
	margin: 0 10px 0 15px;
}

.equListH ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.equListH li {
	display: block;
	margin: 0 0 10px 0;
}

.equListH li .eqTxt {
	font-size: 14px;
	display: block;
	margin: 0 0 5px 0;
}

.equListH li .eqValueH {
	display: inline-block;
	border: solid 1px #50e3c2;
	padding: 10px 10px 7px 10px;
	margin: 0 0 5px 0;
}

.equListH li .eqValue {
	display: inline-block;
	padding: 0 15px 0 0;
	color: #8ccef4;
	vertical-align: top;
	font-size: 17px;
	min-width: 150px;
}

.equListH li .eqState {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin: 2px 0 0 0;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
	background: #50e3c2;
}

.clickable {
	cursor: pointer;
}

.meterH {
	position: relative;
	min-height: 272px;
	top: 6px;
}

.excitation {
	min-height: 120px;
}

.fieldCurrentMar {
	margin-left: 30px !important;
}

.fieldVoltageMar {
	margin-left: 37px !important;
}

.circleH {
	position: absolute;
	top: 0px;
	left: 1%;
	z-index: 1;
	padding-left: 0;
	background: #12141c;
	width: 250px;
	height: 255px;
	border: solid 2px #12141c;
	/*Firefox*/
	-moz-border-top-left-radius: 0px;
	-moz-border-top-right-radius: 125px;
	-moz-border-bottom-right-radius: 123px;
	-moz-border-bottom-left-radius: 0px;
	/*Safari, Chrome*/
	-webkit-border-top-left-radius: 0px;
	-webkit-border-top-right-radius: 125px;
	-webkit-border-bottom-right-radius: 123px;
	-webkit-border-bottom-left-radius: 0px;
	border-top-left-radius: 0px;
	border-top-right-radius: 125px;
	border-bottom-right-radius: 123px;
	border-bottom-left-radius: 0px;
}

.firstCir {
	width: 250px;
	height: 250px;
	border-radius: 125px;
	-moz-border-radius: 125px;
	-webkit-border-radius: 125px;
	border: solid 2px #8a8a8a;
	padding: 7px;
}

.secCir {
	width: 230px;
	height: 230px;
	border-radius: 115px;
	-moz-border-radius: 115px;
	-webkit-border-radius: 115px;
	border: solid 3px #4fd3b5;
	padding: 7px;
}

.thirdCir {
	width: 210px;
	height: 210px;
	border-radius: 105px;
	-moz-border-radius: 105px;
	-webkit-border-radius: 105px;
	border: solid 2px #8a8a8a;
	padding: 32.5px 22.5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.circlecontent {
	margin-top: 10px;
}

.rectH ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.powerMtr .rectH {
	padding-left: 1%;
}

.powerMtr .rectH li {
	border-top: solid 1px #7d7d7d;
	padding: 1px 2px 2px;
}

.powerMtr .rectH li:last-child {
	border-top: solid 1px #7d7d7d;
	border-bottom: solid 1px #7d7d7d;
}

.powerMtr .rectH .goldBox {
	display: inline;
}

.rectH .rectHead {
	float: left;
	text-align: left;
	padding-left: 288px;
}

.rectH li {
	border-top: solid 2px #7d7d7d;
	text-align: right;
	padding: 10px 3px 10px 2px;
}

.rectH li:last-child {
	border-top: solid 2px #7d7d7d;
	border-bottom: solid 2px #7d7d7d;
	padding-top: 14px;
	padding-bottom: 14px;
}

.rectH .goldBox {
	display: inline;
}

.rectH .rectHead {
	color: #83bbdc;
	display: inline-block;
	text-align: right;
	font-size: 24px;
	padding-right: 2px;
}

.bigTxt {
	font-size: 26px;
	color: #79aac5;
	width: 150px;
	display: block;
}

.bigTxtW {
	color: #79aac5;
	word-break: break-all;
	display: block;
}

.hourH {
	font-size: 18px;
	color: #56b6a5;
	display: inline-block;
	padding: 0 0 0 2px;
}

.hrsTxt {
	font-size: 14px;
	color: #f5a523;
}

/* css */
/* Select Node */
.selectDataH {
	margin: 5px 0 0 20px;
}

.selectContent {
	float: left;
	margin: 0 10px 0 0;
}

.selectLabel {
	font-size: 12px;
	display: inline-block;
	padding: 0 5px 0 0;
}

.waveformSelectContent select {
	/*width: 275px;*/
	width: 350px;
	height: 30px;
	color: #85b6d1;
	border: solid 1px #999898;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 4px;
	margin: 5px 10px 0px;
	padding: 4px 11px 4px 2px;
	background-color: #12141c;
	background: url(../images/downArrow.png) no-repeat 95% 50% !important;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.selectContent select {
	width: 100px;
	height: 30px;
	color: #85b6d1;
	border: solid 1px #999898;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 4px;
	margin: 5px 0 0px;
	padding: 4px 11px 4px 2px;
	background-color: #12141c;
	background: url(../images/downArrow.png) no-repeat 95% 50% !important;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
}

.goldColor {
	color: #ffc90e !important;
}

.logoffcircle:before {
	content: ' \25CF';
	color: #ffffff;
}

.logoff {
	margin-top: 40px;
	margin-right: 10px;
	font-size: 25px;
	float: right;
	cursor: pointer;
}

.eqPortSpecialPosition {
	float: left;
	margin-left: 10px !important;
}

.linkPosition {
	margin-right: 450px !important;
}

/* Table */
.table {
	margin-bottom: 5px !important;
}

.borderless tr td {
	border: none !important;
	padding: 0px !important;
}

.table>thead>tr>th {
	border-bottom: 2px solid #4990e2;
}

.measureTble tr th,
.measureTble tr td {
	font-size: 20px;
	font-weight: normal;
	padding: 3px !important;
	text-align: center;
}

.measureTble tr th {
	text-align: left;
	font-size: 20px;
}

.greenTxt {
	display: inline-block;
	padding: 2px 3px;
	color: #5ce5cc;
}

.perSign {
	display: inline-block;
	padding: 0 2px 0 0;
	color: #f5a523;
	font-size: 15px;
	text-align: right;
}

.goldBox {
	border: solid 1px #f5a523;
	display: inline-block;
}

.valueTxt {
	color: #8bcdf3;
	font-size: 11px;
}

.adjusTxt {
	display: inline-block;
	margin: 4px 0 0 10px;
}

.centerTxt {
	margin-left: 6px;
	display: inline-block;
}

.deltaH tr td {
	padding: 1px !important;
}

.deltaTxt {
	display: inline-block;
	margin-left: 4px;
}

.lrBor {
	border-left: 2px solid #4990e2;
	border-right: 2px solid #4990e2;
	margin-bottom: 0 !important;
}

.lrBor table {
	margin-bottom: 0 !important;
}

/* New CSS */
.splColorfortopheader {
	color: #83bbdc;
}

.padLefRigH {
	padding-left: 1%;
	position: relative;
}

.rectH .goldBox {
	width: 209px;
	text-overflow: ellipsis;
	display: inline-block;
	padding: 0 15px 0 5px;
}

.imbalancH .goldBox {
	display: inline-block;
	text-align: center;
	margin-left: 0;
}

.imbalancH tr th {
	text-align: center;
}

.deltaH .goldBox {
	display: inline-block;
	text-align: center;
	margin-bottom: 5px;
	margin-left: 0;
}

.deltaH tr th {
	text-align: center;
}

.txtLeft {
	text-align: left !important;
}

.minMl18 {
	margin-left: -18px;
}

.minMl50 {
	margin-left: -50px;
}

.minMl10 {
	margin-left: -10px;
}

.minMl22 {
	margin-left: -22px;
}

.minMl2 {
	margin-left: -2px;
}

.minWid75 {
	min-width: 75px;
}

.minWid120 {
	min-width: 120px;
}

.pb2 {
	padding-bottom: 2px !important;
}

.spldbBL {
	display: inline-block;
}

/* Feb14 */

.deltaH .valueTxt {
	font-size: 20px;
}

.eqKwStatus {
	float: left;
	margin-left: 10px !important;
}

.eqKwGoldBoxMar {
	margin-left: -20px;
	margin-top: 1px;
	text-align: center;
}

/* Active Gold Box */
.activeGoldBox {
	border: solid 1px transparent;
	width: 100%;
	display: inline-block;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
}

.splDefaultAlert {
	float: left;
	margin-left: 40px;
	margin-top: 12px !important;
}

.defaultAlert {
	display: inline-block;
	margin-left: 7px;
	margin-right: 7px;
	margin-top: 2px;
}

.maAlert {
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	background: transparent;
	margin-top: -2px;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
}

.splMaAlert {
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: top;
	background: transparent;
	margin-top: 2px;
	border-radius: 8px;
	-moz-border-radius: 8px;
	-webkit-border-radius: 8px;
}

/* DateTime  07/02  */

.subTitleH {
	float: left;
	width: 54%;
	text-align: center;
	padding: 0 0 0 5px;
}

.dateTimeH {
	float: left;
	width: 45%;
	text-align: left;
}

.performedFooter {
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}

/* Common Element */
.dbi {
	display: inline;
}

.dbBL {
	display: block;
}

.dbInBL {
	display: inline-block;
}

.font10 {
	font-size: 10px !important;
}

.ml20 {
	margin-left: 20px;
}

.mb10 {
	margin-bottom: 10px;
}

.mt10 {
	margin-top: 10px;
}

.subodePadding {
	margin-right: 210px;
}

.mb14 {
	margin-bottom: 14px;
}

.mb20 {
	margin-bottom: 20px;
}

.spmb30 {
	margin-bottom: 10px;
}

.spmt30 {
	margin-top: 20px;
}

.mb35 {
	margin-bottom: 35px;
}

.mb50 {
	margin-bottom: 50px;
}

.mb60 {
	margin-bottom: 60px;
}

.mb115 {
	margin-bottom: 115px;
}

.mr18 {
	margin-right: 18px;
}

.mr2 {
	margin-right: 2px;
}

.mr10 {
	margin-right: 10px;
}

.mr22 {
	margin-right: 22px;
}

.mt5 {
	margin-top: 5px;
}

.ml6 {
	margin-left: 6px;
}

.mt3 {
	margin-top: 3px;
}

.spMb3 {
	margin-bottom: 3px;
}

.spMb5 {
	margin-bottom: 5px;
}

.wid26 {
	width: 26px;
}

.wid40 {
	width: 40px;
}

.wid45 {
	width: 45px;
}

.wid48 {
	width: 48px;
}

.wid35 {
	width: 35px;
}

.wid82 {
	width: 82px;
}

.txt-left {
	text-align: left !important;
}

.txt-right {
	text-align: right !important;
}

.txt-center {
	text-align: center !important;
}

.wid100per {
	width: 100%;
}

.wid33per {
	width: 33%;
	display: inline-block;
}

.wid145 {
	width: 145px !important;
}

.font18 {
	font-size: 18px;
}

.spPb40 {
	padding-bottom: 40px;
}

.whiteColor {
	color: white;
	text-decoration: none;
}

.whiteColor:hover {
	color: white;
	text-decoration: none;
}

.splDesk {
	width: 90%;
	text-align: right !important;
}

.splCenterDesk {
	width: 90%;
	text-align: center !important;
}

.pr5 {
	padding-right: 5px;
}

.mb35 {
	margin-bottom: 35px;
}

.splPl1 {
	padding: 0 3px 0 3px;
	font-size: 26px;
	min-height: 30px;
}

.bdRadius {
	border-radius: 10px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
}

.inputBox {
	width: 100%;
	padding: 5px 10px;
	color: #fff !important;
	font-size: 16px;
	height: 48px;
	border: solid 2px #4990e2;
	background: #12141c;
}

.continueBtn {
	display: inline-block;
	font-size: 20px;
	color: #fff;
	border: solid 2px #50e3c2;
	padding: 10px 38px;
	background: #12141c;
}

.continueBtn:hover {
	color: #fff;
	text-decoration: none;
}

.btnH {
	text-align: center;
}

.commonBtn {
	display: block;
	width: 100%;
	font-size: 22px;
	border: solid 2px #4eac97;
	padding: 8px;
	background: #12141c;
}

.flNone {
	float: none !important;
}

.splWid130 {
	width: 130px;
}

.splWid131 {
	width: 130px;
}

.splHgt254 {
	height: 254px;
}

.inputBox:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0px 9999px #12141c !important;
	color: #fff !important;
	-webkit-text-fill-color: #fff !important;
}

.inputBox:focus,
.inputBox:-webkit-autofill:focus {
	-webkit-box-shadow: inset 0 0 0px 9999px #12141c !important;
	color: #fff !important;
	-webkit-text-fill-color: #fff !important;
}

*:focus {
	outline: none;
}

/* Waveform */

.waveHdLeft {
	color: #8bcdf3;
	text-align: center;
}

.waveHdCenter {
	text-align: center;
}

/* .waveHdRight   {float:right; display:inline-block; padding:5px 0 0 0; width:40%; font-size:15px; text-align:right;} */

/* 07/02 */
.waveHdRight {
	float: right;
	display: inline-block;
	padding: 5px 0 0 0;
	font-size: 16px;
	text-align: left;
}

.eqPortDateTime {
	float: right;
	display: inline-block;
	padding: 1px 0 0 0;
	font-size: 20px;
	text-align: left;
	margin-right: -117px;
}

/* Waveform End */

/* ratingH */

.ratingFullWid {
	width: 100%;
}

.noTopBorder {
	border-top: none !important;
}

.ratingHeader {
	border: none !important;
	padding-left: 20px !important;
	font-size: 16px !important;
}

.ratingH {
	padding: 0 10px 10px 10px !important;
	vertical-align: top;
	width: 50%;
}

.ratingH td {
	font-size: 16px !important;
	text-align: left;
	padding: 0 5px;
	margin: 0 0 3px 0;
}

.ratingMainHeader {
	font-size: 20px;
	font-weight: normal;
}

/* Rating End */

/* Top Menu */

.topMenu {
	padding: 10px 0;
	border: solid 2px #4eac97;
	background: #12141c;
}

.topMenu ul {
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center;
}

.topMenu ul li {
	display: inline-block;
	margin: 0 4% 0 0;
	vertical-align: top;
}

.topMenu ul li:last-child {
	margin-right: 0;
}

.topMenu ul li a {
	display: block;
	width: 100px;
	height: 100px;
	border: solid 2px #4eac97;
	overflow: hidden;
	text-align: center;
	vertical-align: middle;
	border-radius: 50px;
	-moz-border-radius: 50px;
	-webkit-border-radius: 50px;
}

.topMenu ul li.act a {
	box-shadow: inset 0px 0px 1px 1px rgba(80, 226, 193, 1);
	background: #073242;
}

.topMenu ul li a:hover {
	background: #073242;
}

.topMenu ul li a img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}

/* 07/02 */
.topMenu ul li a span {
	display: block;
	width: 100%;
	height: 100%;
}

.topMenu ul li.equipPortLink a span {
	background: url(../images/iconMenu.png) no-repeat center center;
	background-size: 150%;
}

.topMenu ul li.powerMeterLink a span {
	background: url(../images/iconArrow.png) no-repeat center 25%;
	background-size: 150%;
}

.topMenu ul li.subNodeMeterLink a span {
	background: url(../images/sm_icon.png) no-repeat center 50%;
	background-size: 110%;
}

.topMenu ul li.waveFormsLink a span {
	background: url(../images/iconWave.png) no-repeat center center;
	background-size: 150%;
}

.triangleTop {
	background: url(../images/delta_plus.png) no-repeat center center;
	height: 26px;
	width: 100%;
	background-size: 38%;
}

.triangleDown {
	background: url(../images/delta_minus.png) no-repeat center center;
	height: 26px;
	width: 100%;
	background-size: 38%;
}

.topMenu ul li.motorAnalyLink a span {
	background: url(../images/iconZero.png) no-repeat center center;
	background-size: 150%;
}

.topMenu ul li.trendAnalyLink a span {
	background: url(../images/iconChart.png) no-repeat center center;
	background-size: 150%;
}

.roundCircle {
	border: solid 2px #4eac97;
	height: 75px;
	width: 75px;
	border-radius: 50px;
	overflow: hidden;
	float: right;
	margin-top: 22px;
	margin-right: 5px;
	cursor: pointer;
}

.trendAnaly {
	background: url(../images/iconChart.png) no-repeat center center;
	background-size: 150%;
	height: 75px;
	width: 75px;
}

.topMenu ul li:hover a:hover {
	background: #245575;
}

/* Top Menu End */

/* New Equipment  */

/*To captilize Text */

.allCaps {
	text-transform: uppercase;
}

/* new css for subnode page */

.greenBox {
	border: solid 1px #50e3c2;
	margin: 10px 8px 6px 6px;
}

.equListH li .newEqValueH {
	/*display: inline-block;*/
	border: solid 1px #50e3c2;
	padding: 5px 5px 3px 5px;
	margin: 0 0 5px 0;
}

/* Feb14 */
.equListHKW li .newEqValueHKW {
	display: inline-block !important;
	border: solid 1px #50e3c2 !important;
	padding: 0px 5px 0px 5px !important;
	margin: 0 0 5px 0 !important;
}

/* Feb14 */
.equListH li .newEqState {
	display: inline-block;
	width: 25px;
	height: 25px;
	margin: 2px 30px 0 0;
	vertical-align: top;
	border-radius: 12px;
	-moz-border-radius: 12px;
	-webkit-border-radius: 12px;
	background: #50e3c2;
}

.equListH li .newEqStatePrimary {
	display: inline-block;
	width: 12.5px;
	height: 25px;
	margin: 2px 0 0 0;
	vertical-align: top;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	background: #50e3c2;
}

.equListH li .newEqStateSecondary {
	display: inline-block;
	width: 12.5px;
	height: 25px;
	margin: 2px 30px 0 0;
	vertical-align: top;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	background: #50e3c2;
}

.equListH li .newEqValue {
	display: inline-block;
	padding: 2px 8px 0 8px;
	color: #8ccef4;
	vertical-align: top;
	font-size: 17px;
	min-width: 10px;
}

.firstBlock {
	/*display: inline-block !important;*/
	padding: 0px 13px 28px !important;
	/*vertical-align: top !important;*/
}

.waveSN {
	padding-left: 20px;
	float: left;
}

/*.secondBlock {*/
/*    display: inline-block !important;*/
/*}*/

.newEqValueH .topMenu {
	/*display: inline-block;*/
	padding: 0;
	border: none;
	background: none;
}

.newEqValueH .topMenu ul {
	text-align: left;
	margin-bottom: 15px;
}

.newEqValueH .topMenu ul li {
	display: inline-block;
	margin: 4px 15px 0 0;
}

.newEqValueH .topMenu ul li:last-child {
	margin: 4px 0px 0 0;
}

.newEqValueH .topMenu ul li a {
	display: block;
	width: 55px;
	height: 55px;
	text-align: left;
}

/*.splWidhtForTable{*/
/*    width: 0px;*/
/*}*/

/* star
.newEqValueH .topMenu ul li.waveFormsLink a      {padding:12px 2px;}
.newEqValueH .topMenu ul li.motorAnalyLink a     {padding:14px 10px;}
.newEqValueH .topMenu ul li.trendAnalyLink a     {padding:10px;} */

.newEqBorder {
	display: inline-block;
}

.rectH .splWid155 {
	width: 155px;
	text-overflow: ellipsis;
}

/* Added gold box in protolioPage start */
/*16-06 start*/
.marTop {
	margin-top: 15px !important;
}

/*16-06 end*/
.nodeNameMargin {
	margin-top: -17px;
}

.goldBoxForPortfolio {
	margin-top: -23px;
	margin-right: 92px;
	/* text-align: center */
}

.splRadiusForGoldBox {
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	width: 110px;
}

.goldBoxWidth {
	color: #50e3c2;
	display: inline-block;
	text-align: center;
	vertical-align: middle;
}

.marforSecBlock {
	margin-left: 11px;
	margin-bottom: 10px;
	margin-top: 5px;
}

.marforMainBlock {
	margin-top: 2px;
	margin-bottom: -8px;
}

.marForKw {
	margin-left: 3px;
}

.fontSizeForKW {
	font-size: 17px;
}

/* Added gold box in moteranalysis End */
.sNtitle {
	float: right;
	padding-right: 10px;
	font-size: 18px;
	font-weight: normal;
}

/* Added css in moteranalysis start */

/*16/06 start*/
.graphTitile {
	display: inline-block;
	padding-top: 5px;
	padding-left: 47px;
}

.sptitlecol {
	color: #83bbdc;
}

.sp2ndtitle {
	padding-left: 55px;
}

.marLeft {
	margin-left: 50px;
}

/*16/06 end*/

.horizLine {
	border-top: solid 2px #7d7d7d;
	height: 70px;
}

.goldBoxforPmdc {
	border: solid 1px #f5a523;
	display: inline-block;
}

.mar15forinsidecontent {
	padding: 16px 0 0 2px;
}

.splRadiusForGoldBoxforPowerM {
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	width: 220px;
	margin-left: 10px;
}

.voltageText {
	display: inline-block;
	font-size: 24px;
	color: #83bbdc;
}

.voltageFont {
	font-size: 25px;
	color: #5ce5cc;
	text-align: center;
}

.goldBoxforAvg {
	border: solid 2px #f5a523;
	display: inline-block;
}

.avgFont {
	display: inline-block;
	font-size: 16px;
	color: #83bbdc;
}

.avgmar {
	margin-left: 21px;
}

.peakFont {
	display: inline-block;
	font-size: 16px;
	color: #83bbdc;
}

.marbetweencontent {
	float: right;
	padding-right: 2px;
}

.voltsFonts {
	color: #f5a523;
	font-size: 15px;
	text-align: right;
	display: inline-block;
	margin-left: 5px;
}

.mar40forDailyAvg {
	margin-left: 40px;
}

.forGreeBox {
	border: solid 1px #50e3c2;
	margin: 10px 4px 10px 4px;
	background: #12141c;
	height: 435px;
}

.splRadisForgoldBoxInsideCircle {
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	width: 150px;
	margin-left: -17px;
}

.goldBoxforInsideCircle {
	border: solid 2px #f5a523;
	display: inline-block;
	margin: auto;

}

/*.kwFont{font-size: 25px;color: #f5a523;float: right;padding-right: 3px}*/
.kwFont {
	color: #f5a523;
	font-size: 24px;
	text-align: right;
	display: inline-block;
	margin-left: 5px;
}

.utilityText {
	font-size: 18px;
	margin-top: 10px;
	margin-left: 14px;
}

.dailyAvg {
	padding-right: 22px;
}

.marforDailyAvgGoldbox {
	margin-left: 32px;
}

.wid71 {
	width: 71px;
}

/* Added css in PowermeterPage End */

@media only screen and (max-width: 830px) {
	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 2px 0 0 0;
		font-size: 18px;
		text-align: left;
		margin-right: -47px;
	}

	.linkPosition {
		margin-right: 373px !important;
	}

	/* for powermeter page*/
	.voltageText {
		display: inline-block;
		font-size: 20px;
		color: #83bbdc;
	}

	.marbetweencontent {
		float: right;
		padding-right: 2px;
	}

	.splRadiusForGoldBoxforPowerM {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 173px;
		margin-left: 10px;
	}

	.voltageFont {
		font-size: 20px;
		text-align: center;
	}

	.peakFont {
		font-size: 16px;
	}

	.avgmar {
		margin-left: 19px;
	}

	/* for powermeter page*/
	.topMenu ul li {
		margin: 0 4% 0 0;
	}

	.dateInHeader {
		font-size: 10px;
		margin-top: 7px;
	}

	.minMl10 {
		margin-left: -30px;
	}

	.minMl50 {
		margin-left: -68px;
	}

	.rectH .goldBox {
		width: 180px;
	}

	.splPl1 {
		padding: 4px 3px 4px 3px;
		font-size: 20px;
	}

	.rectH .rectHead {
		font-size: 20px;
		padding-left: 294px;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	/* Added gold box in moteranalysis End */
	.sNtitle {
		float: right;
		padding-right: 10px;
		font-size: 15px;
		font-weight: normal;
		margin-top: 6px;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 65px;
		width: 65px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 22px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 170%;
		height: 60px;
		width: 60px;
		margin-left: 3px;
	}

	.splWidthForSubNode {
		width: 87% !important;
	}

	/* Added css in moteranalysis start */
}

@media only screen and (max-width: 636px) {
	.linkPosition {
		margin-right: 349px !important;
	}

	.marWvafeFormFieldVoltageAndCurr {
		margin-right: 420px;
	}
}

@media only screen and (max-width: 610px) {
	.equTriangleIcon {
		background-size: 10%;
		width: 310px;
		height: 35px;
	}

	.equTriangleDownIcon {
		background-size: 10%;
		width: 310px;
		height: 35px;
	}

	.eqLeftMar {
		padding-left: 40px;
		padding-top: 3px;
	}

	.serialnofont1 {
		font-size: 12px;
	}

	.locationNamefont1 {
		font-size: 16px;
	}

	.right {
		float: right;
		margin-top: -39px;
	}

	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 5px 0 0 0;
		font-size: 15px;
		text-align: left;
		margin-right: -24px;
	}

	.splRadiusForGoldBoxforPowerM {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 152px;
		margin-left: 10px;
	}

	.avgFont {
		display: inline-block;
		font-size: 14px;
		color: #83bbdc;
	}

	.peakFont {
		display: inline-block;
		font-size: 14px;
		color: #83bbdc;
	}

	.avgmar {
		margin-left: 20px;
	}

	.minMl10 {
		margin-left: -30px;
	}

	.minMl50 {
		margin-left: -68px;
	}

	/* Top Menu  */
	.topMenu ul li {
		margin: 0 4% 0 0;
	}

	/* Top Menu End */
	.topMenu ul li a {
		width: 70px;
		height: 70px;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	/* Added gold box in moteranalysis End */
	.sNtitle {
		float: right;
		padding-right: 10px;
		font-size: 14px;
		font-weight: normal;
		margin-top: 6px;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 60px;
		width: 60px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 30px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 185%;
		height: 50px;
		width: 50px;
		margin-left: 5px;
	}

	.splFontForPap {
		font-size: 15px;
	}

	/* Added css in moteranalysis start */
}

@media only screen and (max-width: 560px) {
	.waforFormDropdown {
		margin-bottom: 0px;
	}

	.waveformSelectContent select {
		width: 190px;
	}

	.excitation {
		min-height: 59px;
	}

	.fieldCurrentMar {
		margin-left: 30px !important;
	}

	.fieldVoltageMar {
		margin-left: 42px !important;
	}

	.divposition {
		margin-top: -34px;
		text-align: center;
	}

	/* customer name*/
	.eqRightMar {
		float: right;
		margin-top: -20px;
		font-size: 12px;
		padding-right: 6px;
	}

	.eqType {
		margin-bottom: 1px;

		font-size: 9px;
		white-space: nowrap;
		width: 50px;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.eqTypesplmar {
		margin-left: 7px;
	}

	.circlecontent {
		margin-top: 0px;
		margin-left: 10px;
	}

	.serialnofont {
		font-size: 12px;
	}

	.locationNamefont {
		font-size: 14px;
	}

	.right {
		float: right;
		margin-top: -31px;
	}

	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.logoff {
		margin-top: 40px;
		margin-right: 10px;
		float: right;
		cursor: pointer;
		font-size: 20px;
	}

	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 1px 0 0 0;
		font-size: 15px;
		text-align: left;
		margin-right: -105px;
	}

	.graphTitile {
		display: inline-block;
		padding-top: 5px;
		padding-left: 47px;
		font-size: 12px;
	}

	.mainPowerReading {
		width: 73px !important;
		/* margin-left: -7px !important; */
		margin-top: 5px !important;
	}

	.bigTxt {
		margin-top: 9px;
	}

	.splRadisForgoldBoxInsideCircle {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 64px;
		margin-left: -2px;
	}

	.goldBoxforInsideCircle {
		border: solid 1px #f5a523;
		display: inline-block;
	}

	.kwFont {
		color: #f5a523;
		font-size: 11px;
		text-align: right;
		display: inline-block;
		margin-left: 5px;
	}

	.utilityText {
		font-size: 8px;
		margin-top: 5px;
		margin-left: 10px;
	}

	.dailyAvg {
		padding-right: 2px;
	}

	.fontSizeForKW {
		font-size: 15px;
	}

	.forGreeBox {
		height: 312px;
	}

	.chartContainerVoltagePM {
		height: 188px !important;
	}

	/* Added css in PowermeterPage start */
	.horizLine {
		border-top: solid 1px #7d7d7d;
		height: 32px;
	}

	.mar15forinsidecontent {
		padding: 4px 0 0 2px;
	}

	.splRadiusForGoldBoxforPowerM {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 120px;
		margin-left: 10px;
	}

	.voltageText {
		display: inline-block;
		font-size: 14px;
		color: #83bbdc;
	}

	.voltageFont {
		font-size: 12px;
		color: #5ce5cc;
		text-align: center;
	}

	.voltsFonts {
		color: #f5a523;
		font-size: 9px;
		text-align: right;
		display: inline-block;
		margin-left: 5px;
	}

	.avgFont {
		display: inline-block;
		font-size: 11px;
		color: #83bbdc;
	}

	.peakFont {
		display: inline-block;
		font-size: 10px;
		color: #83bbdc;
	}

	/*.avgmar{margin-left: 20px}*/
	/* Added css in PowermeterPage End */
	.topMenu ul li.equipPortLink a span {
		width: 150%;
		height: 150%;
		margin-top: -10px;
		margin-left: -10px;
	}

	.topMenu ul li.equipPortLink a {
		padding: 14px;
	}

	.topMenu ul li {
		margin: 0 4% 0 0;
	}

	/* Rating */
	.ratingH {
		padding-right: 10px !important;
	}

	.ratingH td {
		font-size: 11px !important;
	}

	.ratingMainHeader {
		font-size: 13px !important;
	}

	.ratingHeader {
		font-size: 11px !important;
	}

	/* Rating End */
	/* Top Menu */
	.topMenu ul li a {
		width: 70px;
		height: 70px;
	}

	/* Top Menu End */
	.dateInHeader {
		font-size: 10px;
	}

	.waveRMP {
		font-size: 7px;
	}

	.waveRMPA {
		font-size: 9px;
	}

	.chartMargin {
		margin: 2px 2px 2px 2px;
	}

	.chartHeader {
		text-align: center;
		font-size: 10px;
		height: 15px;
	}

	.plotSNTitle {
		font-size: 7.5px !important;
		padding-top: 2px !important;
	}

	.chartContainerVoltage {
		height: 280px;
	}

	.chartContainerVoltagePM {
		height: 280px !important;
	}

	.chartContainer {
		height: 200px;
	}

	.chartContainerMain {
		display: block;
		border: solid 2px #4eac97;
		margin: 8px 4px 8px 4px;
		padding: 2px 2px 2px 2px;
		background: #12141c;
	}

	.preloaderSize {
		height: 64px;
		width: 64px;
	}

	.splBdRadius {
		border-radius: 5px;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
	}

	.loginPage .wrapperH {
		max-width: 496px;
		margin: 0 auto;
		padding: 0 5px;
	}

	.reportPage .wrapperH {
		max-width: 1280px;
		min-width: 248px;
		margin: 0 auto;
		padding: 0 5px;
	}

	.wrapperHW {
		max-width: 1280px;
		min-width: 248px;
		margin: 0 auto;
		padding: 0 5px;
	}

	.specialWrapper {
		width: 100%;
		margin: 0 auto;
	}

	.loginPage .loginH {
		text-align: center;
	}

	.loginPage .logoBigH a {
		width: 300px;
		height: 100px;
		display: inline-block;
		margin-bottom: 10px;
		background: url(../images/volta_insite_logo_2024.png) no-repeat;
	}

	.labelH {
		font-size: 16px;
		color: #fff;
		display: block;
		margin: 0 0 6px 7px;
	}

	.subLabels {
		font-size: 12px;
		color: #fff;
		display: block;
		margin: 2px 0 0 3px;
	}

	.forgotLink {
		color: #92b9e0;
		margin: 12px 0 0 7px;
		font-size: 12px;
		display: inline-block;
	}

	.forgotLink:hover {
		color: #4990e2;
		text-decoration: underline;
	}

	.continueBtnH {
		text-align: center;
	}

	.recoverAlert {
		margin: 100px 0 12px;
		text-align: center;
	}

	.mailNote {
		font-size: 11px;
		display: block;
		width: 70%;
		padding: 0 0 0 6px;
	}

	.reportPage .loginH {
		text-align: left;
	}

	.reportPage .logosmallH {
		float: left;
	}

	.reportPage .dataH,
	.reportPage .timeH {
		float: right;
		font-size: 12px;
		margin: 18px 3px 0 0;
	}

	.reportPage .logosmallH a {
		width: 300px;
		height: 100px;
		display: inline-block;
		margin-bottom: 20px;
		background: url(../images/volta_insite_logo_2024.png) no-repeat;
	}

	.bgOrange {
		background: #f5a523 !important;
	}

	.blueBoxH {
		border: solid 2px #4990e2;
		margin: 0 0 10px 0;
		background: #12141c;
	}

	.titleH {
		font-size: 14px;
		padding: 6px;
		border-bottom: solid 2px #4990e2;
		margin: 0 0 4px 0;
	}

	.nodeStatusFS {
		font-size: 12px;
	}

	.seperateLine {
		display: inline-block;
		width: 80%;
	}

	.equListH {
		padding: 0 5px;
	}

	.equListH ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.equListH li {
		display: block;
		margin: 0 0 2px 0;
	}

	.equListH li .eqTxt {
		font-size: 10px;
		display: block;
		margin: 0 0 5px 0;
	}

	.equListH li .eqValueH {
		display: inline-block;
		border: solid 1px #50e3c2;
		padding: 5px 8px 3px 5px;
		margin: 0 0 5px 0;
	}

	.equListH li .eqValue {
		display: inline-block;
		padding: 0 15px 0 0;
		color: #8ccef4;
		vertical-align: top;
		font-size: 14px;
	}

	.equListH li .eqState {
		display: inline-block;
		width: 15px;
		height: 15px;
		margin: 2px 0 0 0;
		border-radius: 8px;
		-moz-border-radius: 8px;
		-webkit-border-radius: 8px;
		background: #50e3c2;
	}

	.meterH {
		position: relative;
		min-height: 112px;
		top: 6px;
	}

	.circleH {
		position: absolute;
		top: 0px;
		left: 1%;
		z-index: 1;
		padding-left: 1px;
		background: #12141c;
		width: 100px;
		height: 100px;
		border: solid 1px #12141c;
		/*Firefox*/
		-moz-border-top-left-radius: 0px;
		-moz-border-top-right-radius: 50px;
		-moz-border-bottom-right-radius: 50px;
		-moz-border-bottom-left-radius: 0px;
		/*Safari, Chrome*/
		-webkit-border-top-left-radius: 0px;
		-webkit-border-top-right-radius: 50px;
		-webkit-border-bottom-right-radius: 50px;
		-webkit-border-bottom-left-radius: 0px;
		border-top-left-radius: 0px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		border-bottom-left-radius: 0px;
	}

	.firstCir {
		width: 99px;
		height: 99px;
		border-radius: 49px;
		-moz-border-radius: 49px;
		-webkit-border-radius: 49px;
		border: solid 1px #8a8a8a;
		padding: 3px;
	}

	.secCir {
		width: 91px;
		height: 91px;
		border-radius: 45px;
		-moz-border-radius: 45px;
		-webkit-border-radius: 45px;
		border: solid 1px #4fd3b5;
		padding: 3px;
	}

	.thirdCir {
		width: 82px;
		height: 82px;
		border-radius: 41px;
		-moz-border-radius: 41px;
		-webkit-border-radius: 41px;
		border: solid 1px #8a8a8a;
		padding: 20px 0 0 10px;
	}

	.rectH ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.powerMtr .rectH {
		padding-left: 1%;
	}

	.powerMtr .rectH li {
		border-top: solid 1px #7d7d7d;
		padding: 1px 2px 2px;
	}

	.powerMtr .rectH li:last-child {
		border-top: solid 1px #7d7d7d;
		border-bottom: solid 1px #7d7d7d;
	}

	.powerMtr .rectH .goldBox {
		display: inline;
	}

	.rectH .rectHead {
		float: left;
		text-align: left;
		padding-left: 172px;
	}

	.rectH li {
		border-top: solid 1px #7d7d7d;
		text-align: right;
		padding: 1px 2px 2px;
	}

	.rectH li:last-child {
		border-top: solid 1px #7d7d7d;
		border-bottom: solid 1px #7d7d7d;
		padding-bottom: 3px;
		padding-top: 1px;
	}

	.rectH .goldBox {
		display: inline;
		padding: 0;
	}

	.rectH .rectHead {
		color: #83bbdc;
		display: inline-block;
		text-align: right;
		font-size: 14px;
		padding-right: 2px;
	}

	.bigTxt {
		font-size: 14px;
		color: #79aac5;
		width: 65px;
		display: block;
	}

	.hourH {
		font-size: 11px;
		color: #56b6a5;
		display: inline-block;
		padding: 0 0 0 2px;
	}

	.hrsTxt {
		font-size: 8px;
		color: #f5a523;
	}

	/* Table */
	.table {
		margin-bottom: 5px !important;
	}

	.borderless tr td {
		border: none !important;
		padding: 0px !important;
	}

	.table>thead>tr>th {
		border-bottom: 2px solid #4990e2;
	}

	.measureTble tr th,
	.measureTble tr td {
		font-size: 12px;
		font-weight: normal;
		padding: 3px !important;
		text-align: center;
	}

	.measureTble tr th {
		text-align: left;
		font-size: 12px;
	}

	.greenTxt {
		display: inline-block;
		padding: 2px 3px;
		color: #5ce5cc;
	}

	.perSign {
		display: inline-block;
		padding: 0 2px 0 0;
		color: #f5a523;
		font-size: 9px;
		text-align: right;
	}

	.goldBox {
		border: solid 1px #f5a523;
		display: inline-block;
	}

	.valueTxt {
		color: #8bcdf3;
		font-size: 11px;
	}

	.adjusTxt {
		display: inline-block;
		margin: 4px 0 0 10px;
	}

	.centerTxt {
		margin-left: 6px;
		display: inline-block;
	}

	.deltaH tr td {
		padding: 1px !important;
	}

	.deltaTxt {
		display: inline-block;
		margin-left: 4px;
	}

	.lrBor {
		border-left: 2px solid #4990e2;
		border-right: 2px solid #4990e2;
		margin-bottom: 0 !important;
	}

	.lrBor table {
		margin-bottom: 0 !important;
	}

	/* New CSS */
	.padLefRigH {
		padding-left: 1%;
		position: relative;
	}

	.rectH .goldBox {
		width: 120px;
		display: inline;
	}

	.imbalancH .goldBox {
		display: inline-block;
		text-align: center;
		margin-left: 0;
	}

	.imbalancH tr th {
		text-align: center;
	}

	.deltaH .goldBox {
		display: inline-block;
		text-align: center;
		margin-left: 0;
	}

	.deltaH tr th {
		text-align: center;
	}

	.txtLeft {
		text-align: left !important;
	}

	.minMl18 {
		margin-left: -18px;
	}

	.minMl10 {
		margin-left: -58px;
	}

	.minMl50 {
		margin-left: -68px;
	}

	.minMl22 {
		margin-left: -22px;
	}

	.minMl2 {
		margin-left: -2px;
	}

	.minWid75 {
		min-width: 75px;
	}

	.minWid65 {
		min-width: 65px;
	}

	.minWid120 {
		min-width: 120px;
	}

	.deltaH .valueTxt {
		font-size: 14px;
	}

	/* Active Gold Box */
	.activeGoldBox {
		border: solid 1px transparent;
		width: 100%;
		display: inline-block;
		border-radius: 3px;
		-moz-border-radius: 3px;
		-webkit-border-radius: 3px;
	}

	.performH {
		font-size: 12px;
	}

	/* Common Element */
	.dbi {
		display: inline;
	}

	.dbBL {
		display: block;
	}

	.dbInBL {
		display: inline-block;
	}

	.font10 {
		font-size: 10px !important;
	}

	.ml20 {
		margin-left: 20px;
	}

	.mb10 {
		margin-bottom: 10px;
	}

	.mt10 {
		margin-top: 10px;
	}

	.mb14 {
		margin-bottom: 14px;
	}

	.mb20 {
		margin-bottom: 20px;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	.spmt30 {
		margin-top: 10px;
	}

	.mb50 {
		margin-bottom: 50px;
	}

	.mb60 {
		margin-bottom: 60px;
	}

	.mb115 {
		margin-bottom: 115px;
	}

	.mr18 {
		margin-right: 18px;
	}

	.mr2 {
		margin-right: 2px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr22 {
		margin-right: 22px;
	}

	.mt5 {
		margin-top: 5px;
	}

	.ml6 {
		margin-left: 6px;
	}

	.mt3 {
		margin-top: 3px;
	}

	.spMb3 {
		margin-bottom: 3px;
	}

	.spMb5 {
		margin-bottom: 5px;
	}

	.wid26 {
		width: 26px;
	}

	.wid45 {
		width: 45px;
	}

	.wid48 {
		width: 26px;
	}

	.wid35 {
		width: 35px;
	}

	.wid82 {
		width: 82px;
	}

	.txtRight {
		text-align: right !important;
	}

	.mb35 {
		margin-bottom: 35px;
	}

	.splPl1 {
		padding: 0 3px 0 3px;
		font-size: 12px;
		min-height: 13px;
	}

	.bdRadius {
		border-radius: 5px;
		-moz-border-radius: 5px;
		-webkit-border-radius: 5px;
	}

	.inputBox {
		width: 100%;
		padding: 5px 10px;
		color: #fff;
		font-size: 16px;
		height: 38px;
		border: solid 2px #4990e2;
		background: #12141c;
	}

	.continueBtn {
		display: inline-block;
		font-size: 16px;
		color: #fff;
		border: solid 2px #50e3c2;
		padding: 10px 38px;
		background: #12141c;
	}

	.continueBtn:hover {
		color: #fff;
		text-decoration: none;
	}

	.btnH {
		text-align: center;
	}

	.commonBtn {
		display: block;
		width: 100%;
		font-size: 14px;
		border: solid 2px #4eac97;
		padding: 8px;
		background: #12141c;
	}

	.defaultAlertforSubNode {
		display: inline-block;
		margin-left: -7px !important;
		margin-right: 7px;
	}

	.defaultAlert {
		display: inline-block;
		margin-left: 7px;
		margin-right: 7px;
	}

	.maAlert {
		display: inline-block;
		width: 8px;
		height: 8px;
		vertical-align: middle;
		background: transparent;
		margin-top: -2px;
		border-radius: 8px;
		-moz-border-radius: 8px;
		-webkit-border-radius: 8px;
	}

	.splMaAlert {
		display: inline-block;
		width: 8px;
		height: 8px;
		vertical-align: middle;
		background: transparent;
		margin-top: -2px;
		border-radius: 8px;
		-moz-border-radius: 8px;
		-webkit-border-radius: 8px;
	}

	.font18 {
		font-size: 12px;
	}

	.spPb40 {
		padding-bottom: 20px;
	}

	.splWid130 {
		width: 90px;
	}

	.splWid131 {
		width: 90px;
	}

	.splHgt254 {
		height: auto;
	}

	/* Waveform */
	.titleH .waveHdLeft,
	.titleH .waveHdCenter,
	.titleH .waveHdRight {
		font-size: 14px;
		padding-top: 0px;
	}

	/* Waveform End */
	/* Added gold box in moteranalysis End */
	.sNtitle {
		float: right;
		padding-right: 10px;
		font-size: 12px;
		font-weight: normal;
		margin-top: 2px;
	}

	/* Added css in moteranalysis start */
	.wid71 {
		width: 50px;
	}

	.linkPosition {
		margin-right: 344px !important;
	}
}

@media only screen and (max-width: 518px) {
	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.right {
		float: right;
		margin-top: -31px;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 50px;
		width: 50px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 25px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 172%;
		height: 45px;
		width: 45px;
		margin-left: 3px;
	}

	.forGreeBox {
		height: 222px;
	}

	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 0px 0 0 0;
		font-size: 15px;
		text-align: left;
		margin-right: -86px;
	}

	.linkPosition {
		margin-right: 305px !important;
	}

	.newspldbBl {
		display: inline;
	}
}

@media only screen and (max-width: 480px) {
	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.right {
		float: right;
		margin-top: -32px;
	}

	.logoff {
		float: right;
		cursor: pointer;
		font-size: 13px;
		margin-top: 20px;
		margin-right: 10px;
	}

	/*customer name*/
	.eqRightMar {
		float: right;
		margin-top: -16px;
		font-size: 12px;
		padding-right: 6px;
	}

	.subodePadding {
		margin-right: 196px;
	}

	.linkPosition {
		margin-right: 250px !important;
	}

	.logoffcircle:before {
		content: ' \25CF';
		font-size: 10px;
		color: #ffffff;
	}

	.newEqBorder {
		display: inline-block;
		padding-left: 5px;
	}

	.marforSecBlock {
		margin-left: 0px;
		margin-top: 5px;
	}

	.topMenuKW ul li a {
		width: 55px !important;
		height: 55px !important;
	}

	.splRadiusForGoldBox {
		width: 70px;
	}

	.spldbBL {
		display: block;
	}

	/* Feb14 */
	.equListH li .newEqValueH {
		padding: 0px 2px 2px;
	}

	/* Feb14 */
	/* New Equipment */
	.newEqValueH .topMenu ul li {
		margin: 4px 10px 0 0;
	}

	.dateInHeader {
		font-size: 8px;
	}

	.loginPage .loginH {
		text-align: center;
	}

	.rectH li {
		border-top: solid 1px #7d7d7d !important;
		text-align: right;
		padding: 1px 2px 2px;
	}

	.rectH li:last-child {
		border-bottom: solid 1px #7d7d7d;
		padding-top: 3px;
	}

	.adjusTxt {
		display: inline-block;
		margin: 10px 0 0 2px;
	}

	.padLefRigH {
		padding-left: 0;
		padding-right: 0;
		position: relative;
	}

	.circleH {
		left: 0;
	}

	.rectH .goldBox {
		width: auto;
		display: inline;
	}

	.deltaH .goldBox,
	.imbalancH .goldBox {
		display: inline-block;
		text-align: right;
		margin-top: 10px !important;
	}

	/* Fonts size Changed */
	.labelH {
		font-size: 14px;
	}

	.mailNote {
		font-size: 10px;
	}

	.commonBtn {
		font-size: 11px;
	}

	.titleH {
		font-size: 12px;
	}

	.nodeStatusFS {
		font-size: 8px;
	}

	.measureTble tr th {
		font-size: 11px;
	}

	.measureTble tr td {
		font-size: 11px !important;
	}

	.splPl1 {
		font-size: 11px;
	}

	.reportPage .dataH,
	.reportPage .timeH {
		font-size: 10px;
		margin: 7px 3px 0 0;
	}

	.rectH .rectHead {
		font-size: 12px;
	}

	.deltaH .valueTxt {
		font-size: 11px !important;
	}

	.inputBox {
		font-size: 16px;
	}

	/* Waveform */
	.titleH .waveHdLeft {
		font-size: 12px;
	}

	.titleH .waveHdCenter {
		font-size: 12px;
	}

	.titleH .waveHdRight {
		font-size: 11px;
		padding-top: 0;
	}

	/* Waveform End */
	.reportPage .logosmallH a {
		width: 150px;
		height: 54px;
		display: inline-block;
		margin: 8px 0 10px;
		background: url(../images/volta_insite_logo_2024_small.png) no-repeat;
	}

	.spantodiv {
		display: inline-block;
		text-align: right;
		width: 100%;
	}

	.spantodiv div {
		display: inline-block;
		text-align: right;
	}

	.chartMargin {
		margin: 2px 2px 2px 2px;
	}

	.chartHeader {
		text-align: center;
		font-size: 10px;
		height: 15px;
	}

	.plotSNTitle {
		font-size: 7.5px !important;
		padding-top: 2px !important;
	}

	.chartContainerVoltage {
		height: 280px;
	}

	.chartContainer {
		height: 200px;
	}

	.chartContainerMain {
		display: block;
		border: solid 2px #4eac97;
		margin: 8px 4px 8px 4px;
		padding: 2px 2px 2px 2px;
		background: #12141c;
	}

	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 0px 0 0 0;
		font-size: 13px;
		text-align: left;
		margin-right: -77px;
	}

	.newspldbBl {
		display: inline;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 43px;
		width: 43px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 40px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 150%;
		height: 40px;
		width: 44px;
		margin-left: 0px;
	}
}

@media only screen and (max-width: 420px) {
	.splDefaultAlert {
		float: none;
		/*margin-left: px;*/
		margin-top: 0px !important;
	}

	.marWvafeFormFieldVoltageAndCurr {
		margin-right: 220px;
	}

	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.right {
		float: right;
		margin-top: -31px;
	}

	.nodenamefont {
		font-size: 7px !important;
	}

	.nodenamefontspl {
		width: 42px;
		overflow-wrap: break-word;
	}

	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	.subodePadding {
		margin-right: 181px;
	}

	.graphTitile {
		display: inline-block;
		padding-top: 5px;
		padding-left: 47px;
		font-size: 10px;
	}

	.chartContainerVoltagePM {
		height: 188px !important;
	}

	.loaderPositionPWK {
		position: relative;
		margin-top: 55px !important;
		left: 44%;
	}

	.topMenuKW {
		margin-left: 10px !important;
		margin-right: 10px !important;
	}

	.voltageText {
		display: inline-block;
		font-size: 12px;
		color: #83bbdc;
	}

	.avgFont {
		display: inline-block;
		font-size: 9px;
		color: #83bbdc;
	}

	.peakFont {
		display: inline-block;
		font-size: 8px;
		color: #83bbdc;
	}

	.marbetweencontent {
		float: right;
		padding-right: 2px;
		margin-top: 3px;
	}

	.splRadiusForGoldBox {
		width: 70px;
	}

	.fontSizeForKW {
		font-size: 12px;
	}

	.topMenu ul li.equipPortLink a span {
		width: 250%;
		height: 200%;
		margin-top: -11px;
		margin-left: -17px;
	}

	/* Top Menu */
	.equListH li .newEqValue {
		font-size: 14px;
		padding: 4px 4px 0;
	}

	.topMenu ul li a {
		width: 55px;
		height: 55px;
	}

	.newEqValueH .topMenu ul li {
		margin: 0 15px 5px 0;
	}

	.topMenuKW ul li a {
		width: 50px !important;
		height: 50px !important;
	}

	.newEqValueHKW .topMenu ul li {
		margin: 0 10px 5px 0;
	}

	.newEqValueH .topMenu ul li:last-child {
		margin: 0px;
	}

	/* star
    .topMenu ul li.equipPortLink a      {padding:8px;}
    .topMenu ul li.waveFormsLink a      {padding:12px 2px;}
    .topMenu ul li.motorAnalyLink a     {padding:10px;}
    .topMenu ul li.trendAnalyLink a     {padding:9px 7px 7px 7px;}
    */
	.selectContent select {
		width: 90px;
	}

	/* Top Menu End */
	.spMb3 {
		margin-bottom: auto;
	}

	.spMb5 {
		margin-bottom: auto;
	}

	.lrBor {
		border-left: none;
		border-right: none;
		margin-bottom: 5px !important;
	}

	.specialWrapper {
		width: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.rectH li {
		border-right: none;
	}

	.splWid131 {
		width: 72px;
	}

	.dataH {
		font-size: 9px !important;
		margin-top: 10px !important;
	}

	.newEqValueH .topMenu {
		padding: 7px 0 0 0;
	}

	/* .waveHdCenter {width: 30%;} */
	.splwaveHdCenter {
		width: 40%;
	}

	.eqPortDateTime {
		float: right;
		display: inline-block;
		padding: 0px 0 0 0;
		font-size: 13px;
		text-align: left;
		margin-right: -64px;
	}
}

@media only screen and (max-width: 400px) {
	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.topMenuKW {
		margin-left: 5px !important;
		margin-right: 5px !important;
	}

	.subodePadding {
		margin-right: 175px;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	.newEqValueH .topMenu {
		padding: 5px 0 0 0;
	}

	.splRadiusForGoldBoxforPowerM {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 106px;
		margin-left: 10px;
	}

	/* Top Menu */
	.equListH li .newEqValue {
		font-size: 14px;
		padding: 4px 4px 0;
	}

	.topMenu ul li {
		margin: 0 20px 0 0;
	}

	.topMenu ul li a {
		width: 55px;
		height: 55px;
	}

	.topMenuKW ul li a {
		width: 48px !important;
		height: 48px !important;
	}

	.newEqValueH .topMenu ul li {
		margin: 0 10px 5px 0;
	}

	.newEqValueH .topMenu ul li:last-child {
		margin: 0px;
	}

	/* star
    .topMenu ul li.equipPortLink a      {padding:8px;}
    .topMenu ul li.waveFormsLink a      {padding:12px 2px;}
    .topMenu ul li.motorAnalyLink a     {padding:10px;}
    .topMenu ul li.trendAnalyLink a     {padding:9px 7px 7px 7px;}
    */
	.selectContent select {
		width: 90px;
	}

	/* Top Menu End */
	.spMb3 {
		margin-bottom: auto;
	}

	.spMb5 {
		margin-bottom: auto;
	}

	.lrBor {
		border-left: none;
		border-right: none;
		margin-bottom: 5px !important;
	}

	.specialWrapper {
		width: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.rectH li {
		border-right: none;
	}

	.splWid131 {
		width: 72px;
	}

	.dataH {
		font-size: 9px !important;
		margin-top: 10px !important;
	}

	.newEqValueH .topMenu {
		padding: 7px 0 0 0;
	}

	/* .waveHdCenter {width: 30%;} */
	.splwaveHdCenter {
		width: 40%;
	}

	.linkPosition {
		margin-right: 235px !important;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 38px;
		width: 38px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 40px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 150%;
		height: 32px;
		width: 38px;
		margin-left: 0px;
	}

	.splWidthForSubNode {
		width: 87% !important;
	}
}

@media only screen and (max-width: 380px) {
	.serialnofont1 {
		font-size: 10px;
	}

	.locationNamefont1 {
		font-size: 12px;
	}

	.right {
		float: right;
		margin-top: -31px;
	}

	.nodenamefont {
		font-size: 6px !important;
	}

	.nodenamefontspl {
		width: 42px;
		overflow-wrap: break-word;
	}

	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.linkPosition {
		margin-right: 227px !important;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	.newEqBorder {
		display: inline-block;
		padding-left: 1px;
	}
}

@media only screen and (max-width: 370px) {
	.fieldCurrentMar {
		margin-left: 30px !important;
		width: 110px !important;
	}

	.fieldVoltageMar {
		margin-left: 42px !important;
		width: 110px !important;
	}

	.goldBoxForPortfolio {
		margin-top: -23px;
		margin-right: 70px;
		text-align: center;
	}

	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.linkPosition {
		margin-right: 215px !important;
	}

	.right {
		float: right;
		margin-top: -31px;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	/*.subodePadding {*/
	/*margin-right: 157px;*/
	/*}*/

	.topMenuKW {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.splRadiusForGoldBoxforPowerM {
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		width: 88px;
		margin-left: 10px;
	}

	.marforSecBlock {
		margin-left: 0px;
	}

	.marforMainBlock {
		margin-top: -3px;
		margin-bottom: -12px;
	}

	/* Top Menu */
	.equListH li .newEqValue {
		font-size: 14px;
		padding: 4px 4px 0;
	}

	.topMenu ul li a {
		width: 55px;
		height: 55px;
	}

	.newEqValueH .topMenu ul li {
		margin: 0 5px 5px 0;
	}

	.topMenu ul li {
		margin: 4px;
	}

	.topMenuKW ul li a {
		width: 44px !important;
		height: 44px !important;
	}

	.newEqValueHKW .topMenuKW ul li {
		margin: 2px 8px 5px 0 !important;
	}

	.newEqValueH .topMenu ul li:last-child {
		margin: 0px;
	}

	/*.marforSecBlock {margin-left: -13px}*/
	/* star
    .topMenu ul li.equipPortLink a      {padding:8px;}
    .topMenu ul li.waveFormsLink a      {padding:12px 2px;}
    .topMenu ul li.motorAnalyLink a     {padding:10px;}
    .topMenu ul li.trendAnalyLink a     {padding:9px 7px 7px 7px;}
    */
	.selectContent select {
		width: 90px;
	}

	/* Top Menu End */
	.spMb3 {
		margin-bottom: auto;
	}

	.spMb5 {
		margin-bottom: auto;
	}

	.lrBor {
		border-left: none;
		border-right: none;
		margin-bottom: 5px !important;
	}

	.specialWrapper {
		width: auto;
		padding-left: 0;
		padding-right: 0;
	}

	.rectH li {
		border-right: none;
	}

	.splWid131 {
		width: 72px;
	}

	.dataH {
		font-size: 9px !important;
		margin-top: 10px !important;
	}

	.newEqValueH .topMenu {
		padding: 7px 0 0 0;
	}

	/* .waveHdCenter {width: 30%;} */
	.splwaveHdCenter {
		width: 40%;
	}

	.splFontForPap {
		font-size: 9px;
	}

	.marfromRight {
		margin-right: 17px;
	}

	.marSecBlockForEqKw {
		margin-left: 10px;
	}
}

@media only screen and (max-width: 350px) {
	.eqKwGoldBoxMar {
		margin-left: -32px;
	}

	.marTopBlockEqKw {
		margin-top: 0px;
	}

	.marforSecBlock {
		margin-left: 11px;
		margin-bottom: 10px;
		margin-top: 5px;
	}

	.marfromRight {
		margin-right: 36px;
	}

	.goldBoxForPortfolio {
		margin-top: -23px;
		margin-right: 45px;
		text-align: center;
	}

	.splWidhtForTable {
		width: 74px;
	}

	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.roundCircle {
		border: solid 2px #4eac97;
		height: 32px;
		width: 32px;
		border-radius: 50px;
		overflow: hidden;
		float: right;
		margin-top: 45px;
		margin-right: 5px;
		cursor: pointer;
	}

	.trendAnaly {
		background: url(../images/iconChart.png) no-repeat center center;
		background-size: 150%;
		height: 30px;
		width: 30px;
		margin-left: 0px;
	}

	.linkPosition {
		margin-right: 180px !important;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	.newEqBorder {
		display: inline-block;
		padding-left: 1px;
	}

	.topMenuKW {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}

	.firstBlock {
		/*display: inline-block;*/
		/*padding: 14px 0;*/
		padding: 0px 9px 28px !important;
		vertical-align: top;
	}

	.newEqValueH .topMenu ul li {
		margin: 0px 5px 5px 0;
	}

	.newEqValueHKW .topMenuKW ul li {
		margin: 4px 6px 5px 0px !important;
	}

	.splpdLR {
		padding: 2px 0 !important;
	}

	.splpdLR div {
		padding-right: 3px !important;
	}

	.minWid75 {
		min-width: 66px;
	}

	.selectContent select {
		width: 50px;
		font-size: 10px;
		height: 24px;
	}

	body {
		width: 320px;
		overflow-x: hidden;
	}

	.topMenu ul li.equipPortLink a span {
		width: 250%;
		height: 200%;
		margin-top: -9px;
		margin-left: -15px;
	}

	.topMenu ul li a {
		width: 52px;
		height: 52px;
	}

	.ratingH {
		padding-right: 4px !important;
		padding-left: 4px !important;
	}

	.ratingH td {
		padding: 0 1px;
	}

	.topMenu ul li {
		margin: 4px;
	}

	.newEqValueH .topMenu ul li a {
		width: 44px;
		height: 44px;
	}

	.newEqValueHKW .topMenuKW ul li a {
		width: 40px !important;
		height: 40px !important;
	}

	.ratingHeader {
		padding-left: 10px !important;
	}
}

a:hover {
	color: white !important;
	text-decoration: none !important;
}

.statusgreen {
	background: #50e3c2 !important;
}

.statusred {
	background: #d43f3a !important;
}

.statusorange {
	background: #d58512 !important;
}

.statusyellow {
	background: #ffff00 !important;
}

.statusgray {
	background: #e8e8e5 !important;
}

/* .left {
    float: left;
} */

.serialnofont {
	font-size: 12px;
}

.locationNamefont {
	font-size: 14px;
}

.loaderPosition {
	position: fixed;
	top: 21%;
	left: 48%;
}

.loaderPositionPWK {
	position: relative;
	margin-top: 100px;
	left: 44%;
}

.informationHeader {
	margin-bottom: 10px;
	margin-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
}

/*Toaster style*/

.toast-title {
	font-weight: bold;
}

.toast-message {
	-ms-word-wrap: break-word;
	word-wrap: break-word;
}

.toast-message a,
.toast-message label {
	color: #ffffff;
}

.toast-message a:hover {
	color: #cccccc;
	text-decoration: none;
}

.toast-close-button {
	position: relative;
	right: -0.3em;
	top: -0.3em;
	float: right;
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
	-webkit-text-shadow: 0 1px 0 #ffffff;
	text-shadow: 0 1px 0 #ffffff;
	opacity: 0.8;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	filter: alpha(opacity=80);
}

.toast-close-button:hover,
.toast-close-button:focus {
	color: #000000;
	text-decoration: none;
	cursor: pointer;
	opacity: 0.4;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	filter: alpha(opacity=40);
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
	padding: 0;
	cursor: pointer;
	background: transparent;
	border: 0;
	-webkit-appearance: none;
}

.toast-top-center {
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-center {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-full-width {
	top: 0;
	right: 0;
	width: 100%;
}

.toast-bottom-full-width {
	bottom: 0;
	right: 0;
	width: 100%;
}

.toast-top-left {
	top: 12px;
	left: 12px;
}

.toast-top-right {
	top: 12px;
	right: 12px;
}

.toast-bottom-right {
	right: 12px;
	bottom: 12px;
}

.toast-bottom-left {
	bottom: 12px;
	left: 12px;
}

#toast-container {
	position: fixed;
	z-index: 999999;
	pointer-events: none;
	/*overrides*/
}

#toast-container * {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

#toast-container>div {
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	margin: 0 0 6px;
	padding: 15px 15px 15px 50px;
	width: 300px;
	-moz-border-radius: 3px 3px 3px 3px;
	-webkit-border-radius: 3px 3px 3px 3px;
	border-radius: 3px 3px 3px 3px;
	background-position: 15px center;
	background-repeat: no-repeat;
	-moz-box-shadow: 0 0 12px #999999;
	-webkit-box-shadow: 0 0 12px #999999;
	box-shadow: 0 0 12px #999999;
	color: #ffffff;
	opacity: 0.8;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
	filter: alpha(opacity=80);
}

#toast-container> :hover {
	-moz-box-shadow: 0 0 12px #000000;
	-webkit-box-shadow: 0 0 12px #000000;
	box-shadow: 0 0 12px #000000;
	opacity: 1;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
	filter: alpha(opacity=100);
	cursor: pointer;
}

#toast-container>.toast-info {
	/*Commented because of client requirement*/
	/*background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;*/
}

#toast-container>.toast-error {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=') !important;
}

#toast-container>.toast-success {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==') !important;
}

#toast-container>.toast-warning {
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=') !important;
}

#toast-container.toast-top-center>div,
#toast-container.toast-bottom-center>div {
	width: 300px;
	margin-left: auto;
	margin-right: auto;
}

#toast-container.toast-top-full-width>div,
#toast-container.toast-bottom-full-width>div {
	width: 96%;
	margin-left: auto;
	margin-right: auto;
}

.toast {
	background-color: #030303;
}

.toast-success {
	background-color: #51a351;
}

.toast-error {
	background-color: #bd362f;
}

.toast-info {
	background-color: #2f96b4;
}

.toast-warning {
	background-color: #f89406;
}

.toast-progress {
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	background-color: #000000;
	opacity: 0.4;
	-ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
	filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
	.paddLeftForNdeName {
		padding-left: 5px !important;
	}

	.spmb30 {
		margin-bottom: 10px !important;
	}

	#toast-container>div {
		padding: 8px 8px 8px 50px;
		width: 11em;
	}

	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}

@media all and (min-width: 241px) and (max-width: 480px) {
	#toast-container>div {
		padding: 8px 8px 8px 50px;
		width: 18em;
	}

	#toast-container .toast-close-button {
		right: -0.2em;
		top: -0.2em;
	}
}

@media all and (min-width: 481px) and (max-width: 768px) {
	#toast-container>div {
		padding: 15px 15px 15px 50px;
		width: 25em;
	}
}

#toast-container>.toast-info>div {
	margin-left: -40px !important;
}

.vaColor {
	color: #4dac97;
}

.vbColor {
	color: #ef4123;
}

.vcColor {
	color: #4990e2;
}

.ifColor {
	color: #4dac97;
}

.vfColor {
	color: #4dac97;
}

.marginTopWaveDateTime {
	margin-top: 3px !important;
}

.text-center {
	text-align: center !important;
}

.wid66per {
	width: 66%;
}

text {
	color: #e0e0e3 !important;
	cursor: default !important;
	font-size: 11px !important;
	fill: #e0e0e3 !important;
}

@media all and (min-width: 301px) and (max-width: 768px) {
	text {
		color: #e0e0e3 !important;
		cursor: default !important;
		font-size: 8px !important;
		fill: #e0e0e3 !important;
	}
}

.wv-normal-tooltip {
	min-width: 85px !important;
	clear: both !important;
}

.sm-wv-normal-tooltip {
	min-width: 65px !important;
	clear: both !important;
}

.normal-tooltip {
	min-width: 145px !important;
	clear: both !important;
}

.normal-tooltip-left {
	float: left !important;
}

.normal-tooltip-right {
	float: right !important;
}

.sm-normal-tooltip {
	min-width: 110px !important;
	clear: both !important;
}

.sm-normal-tooltip-left {
	float: left !important;
	font-size: 8px !important;
}

.sm-normal-tooltip-right {
	float: right !important;
	font-size: 8px !important;
}

.hm-normal-tooltip {
	min-width: 135px !important;
	clear: both !important;
}

.hm-normal-tooltip-left {
	float: left !important;
	min-width: 65px !important;
}

.hm-normal-tooltip-right {
	float: right !important;
}

.sm-hm-normal-tooltip {
	min-width: 95px !important;
	clear: both !important;
}

.sm-hm-normal-tooltip-left {
	float: left !important;
	font-size: 8px !important;
	min-width: 45px !important;
}

.sm-hm-normal-tooltip-left-two {
	float: left !important;
	font-size: 8px !important;
}

.sm-hm-normal-tooltip-right {
	float: right !important;
	font-size: 8px !important;
}

/* Chart css start*/

.bar {
	fill: orange;
}

.bar:hover {
	fill: orangered;
}

.plot-area {
	overflow: hidden;
}

/*
/* for the navigator chart, hide the y axis, and 'collapse' the chart title
   and x-axis label so that they do not occupy any space */
#navigator-chart-new-version .y-axis {
	visibility: hidden;
}

#navigator-chart-new-version .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

#navigator-chart .y-axis {
	visibility: hidden;
}

#navigator-chart .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

#navigator-chart-one .y-axis {
	visibility: hidden;
}

#navigator-chart-one .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

#navigator-chart-trending .y-axis {
	visibility: hidden;
}

#navigator-chart-trending .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

#navigator-chart-trending-one .y-axis {
	visibility: hidden;
}

#navigator-chart-trending-one .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

#navigator-chart-trending-bar .y-axis {
	visibility: hidden;
}

#navigator-chart-trending-bar .chart-label,
#navigator-chart .x-axis-label {
	display: none;
}

.focus circle {
	fill: steelblue;
}

.focusWhite circle {
	fill: white !important;
}

.focusWOne circle {
	fill: #4dac97 !important;
}

.focusWTwo circle {
	fill: #ef4123 !important;
}

.focusWThree circle {
	fill: #4990e2 !important;
}

.focus text {
	font-size: 14px;
}

.tooltipCustom {
	fill: #12141c;
	stroke: darkgray;
	stroke-width: 2;
}

.tooltip-date,
.tooltip-likes {
	font-weight: bold;
}

.overlay {
	fill: none;
	pointer-events: all;
}

.x-axis path {
	stroke: rgb(112, 112, 115);
	stroke-width: 4;
}

.y-axis path {
	stroke: rgb(112, 112, 115);
	stroke-width: 4;
}

.y-axis-label {
	transform: rotate(-90deg) !important;
	flex: none;
	font-size: 12px;
	margin-right: -5px;
}

/* Chart css End*/

.main-chart-height {
	height: 300px;
}

.main-chart-height-pm {
	height: 400px;
}

.navigator-main-chart-height {
	margin-top: 10px;
	height: 71px;
	margin-left: 50px;
	margin-right: -10px !important;
}

.navigator-second-chart-height {
	margin-top: 10px;
	height: 56px;
	margin-left: 50px;
	margin-right: -10px !important;
}

.navigator-chart {
	margin-left: 50px;
	margin-right: -10px !important;
}

.grid {
	stroke: rgba(112, 112, 115);
	stroke-opacity: 0.3;
	shape-rendering: crispEdges;
	stroke-width: 2;
	stroke-dasharray: 2, 6;
}

.gridAtZero {
	stroke: rgba(112, 112, 115);
	stroke-opacity: 0.5;
	shape-rendering: crispEdges;
	stroke-width: 2;
	stroke-dasharray: 2, 6;
}

.PlotButtonCss:hover {
	fill: black !important;
}

/* keeping the tooltip font size same all the time */
.labelTop,
.labelTopEqual,
.tooltip-date,
.labelLike,
.labelLike2,
.labelLike3,
.labelLikeEqual,
.labelLikeEqual2,
.labelLikeEqual3,
.tooltip-likes,
.tooltip-likes2,
.tooltip-likes3,
.tooltip-frequency,
.tooltip-frequency-equal,
.tooltip-frequency-value,
.tooltip-aptitude,
.tooltip-aptitude-equal,
.tooltip-aptitude-value,
.tooltip-lf,
.tooltip-lf-equal,
.tooltip-lf-value {
	font-size: 11px !important;
}

.PlotButtonCss {
	fill: rgb(80, 80, 83);
	border-width: 1;
}

.DisplayNone {
	display: none;
}

.DisplayNull {
	display: null;
}

.DisplayBlock {
	display: block;
}

.fill_4A90E2 {
	fill: #4a90e2;
}

.fill_50E3C2 {
	fill: #50e3c2;
}

.more_option_support_email {
	color: #92b9e0;
}

.more_option_support_email:active,
.more_option_support_email:focus,
.more_option_support_email:visited {
	color: #92b9e0;
	text-decoration: none;
}

.disabled-click {
	pointer-events: none;
}

.connectedStatusBase {
	display: inline-block;
	width: 25px;
	height: 25px;
	border-radius: 12px;
	-moz-border-radius: 12px;
	-webkit-border-radius: 12px;
	background: #50e3c2;
}

.connectedStatusPrimary {
	display: block;
	width: 12.5px;
	height: 25px;
	border-top-left-radius: 12px;
	border-bottom-left-radius: 12px;
	background: #50e3c2;
}

.connectedStatusSecondary {
	display: block;
	width: 12.5px;
	height: 25px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	background: #50e3c2;
}

.connectedStatusContainer {
	display: flex;
	width: min-content;
	height: 100%;
	align-items: center
}

.statusTitleContainer {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 10px;
}

.cloud-connected-text-box {
	font-size: 17px;
	padding: 2px 8px 0 12px
}

.cloud-connected-text-box a,
.sel-text-box a {
	color: #50e3c2 !important;
	text-decoration: underline !important;
}

;