
.supportLinkLogin{
    margin: 12px 0 0 0px;
    font-size: 16px !important;
    display: inline-block;
    text-align: right;
    width: 50%;
}

.supportEmailLogin{
    margin: 12px 0 5 0px;
    color: #92b9e0;
    font-size: 16px !important;
    display: inline-block;
    width: 5%;
    
}

.supportEmailLogin:active, .supportEmailLogin:focus, .supportEmailLogin:visited{
    color: #92b9e0 ;
    text-decoration: none ;
}

@media only screen and (max-width: 670px){
    .supportLinkLogin{
        margin: 12px 0 0 0px;
        font-size: 16px !important;
        display: inline-block;
        text-align: right;
        width: 40%;
    }

    .supportEmailLogin{
        margin: 12px 0 0 0px;
        color: #92b9e0;
        font-size: 16px !important;
        display: inline-block;
        text-align: right;
        width: 5%;
    }
    .supportEmailLogin:active, .supportEmailLogin:focus, .supportEmailLogin:visited{
        color: #92b9e0 ;
        text-decoration: none ;
    }
}


@media only screen and (max-width: 560px){

    .supportLinkLogin{
        margin: 12px 0 0 0px;
        font-size: 12px !important;
        display: inline-block;
        text-align: right;
        width: 45%;
    }

    .supportEmailLogin{
        margin: 12px 0 0 0px;
        color: #92b9e0;
        font-size: 12px !important;
        display: inline-block;
        text-align: right;
        width: 5%;
    }
    .supportEmailLogin:active, .supportEmailLogin:focus, .supportEmailLogin:visited{
        color: #92b9e0 ;
        text-decoration: none ;
    }
}


.eyeBtn{
    background-image: url('../../images/eye.png');
    background-size: contain;
    background-color: transparent;
    border: none;
    margin-left: -40px;
    margin-top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}


.eyeSlash{
    background-image: url('../../images/eyeSlash.png');
    background-size: contain;
    background-color: transparent;
    border: none;
    margin-left: -40px;
    margin-top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
}